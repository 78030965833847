h2.homepage-title {
  margin-bottom: 48px;
}

.ig-HomePage-Banner-container {
  /* padding-top: 43px; */
  padding-top: 90px;
}

/* .why-choose-us-sub_container {
  width: 326px;
  height: 63px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  display: flex;
} */

.why-choose-us-sub-img_container{
    max-width: 376px;
    width: 100%;

    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    background: #FFF;

    height: 112px;
    box-sizing: border-box;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
}

.why-choose-us-sub-img_container img{
  width: 100%;
  height: 100% !important;
}

.why-choose-us-sub_container img {
  width: 56px;
  height: 56px;
  object-fit: fill;
  margin-right: 11px;
}

.why-choose-us-main {
  margin-bottom: 49px;
  /* margin-top: 48px; */
  /* margin-top: 40px; */
  /* margin-top: 25px; */
}

.why-choose-us-main :global .homepage-title{
  margin-bottom: 48px;
}

.offers-for-you-container {
  margin-bottom: 46px;
  position: relative;
}

.offers-for-you-container li {
  /* width: 290px !important; */
  height: 205px;
  /* margin-right: 16px; */
  /* padding: 0px; */
  border-radius: 12px;
}

.sliderbox {
  border-radius: 12px;
}

.offers-for-you-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.9);
}

.offers-for-you-sub_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.7);
}

.offers-for-you-sub_container {
  padding: 24px;
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 146px;
}

.offers-for-you-tag {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #000000;
  border: 1px solid #000;
  background: #fff;
  border-radius: 8px;
  padding: 4px 12px;
  margin-bottom: 32px;
  margin-top: 8px;
  width: fit-content;
}

.offers-for-you-icon-container{
  /* margin-bottom: 10px; */
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  /* margin: 0 8.5px; */
  margin-right: 17px;
  cursor: pointer;
}

.offers-for-you-icon {
  width: 100%;
  height: 100%;
  object-fit: fill;
}


.explore-with-ig-container li {
  /* width: 277px; */
  height: 360px;
  /* margin-right: 15px; */
  /* padding: 0px; */
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.Explore-sliderbox-inner {
  border-radius: 12px;
  position: relative;
  background-size: cover;
}

.Explore-sliderbox-inner .bottom-text-slider {
  position: absolute;
  bottom: 32px;
  left: 32px;
}

.explore-video-container {
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  /* margin: 0 7.5px; */
  margin-right: 15px;
  cursor: pointer;
}

.explore-video-container>div>div video {
  position: relative;
  left: 0;
  top: 0;
  opacity: 1;
}

.Explore-sliderbox-inner .offers-for-you-sub_headings {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  padding-bottom: 4px;
}

.bottom-text-slider .offers-for-you-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
}

.modalpopup-no-bg :global .video-container::before{
  background-image: linear-gradient(transparent,  black 65%);
}

.modalpopup-no-bg :global .vms_modalpop_content {
  margin: unset;
  position: relative !important;
  overflow: unset;
  width: 100%;
  height: 93.2vh;
  background-color: black;
  border-radius: 12px
}

.modalpopup-no-bg :global .vms_modalpop_content > div:first-of-type {
    border-radius: 12px;
    overflow: hidden;
}

.modalpopup-no-bg :global .vms_modalpop_modalclose svg {
  display: none;
}

.modalpopup-no-bg :global .vms_modalpop_modalheader {
  display: none;
}

.ig-mobile-popup-sub-description {
  position: absolute;
  bottom: 38px;
  /* padding: 0px 20px;
  width: 90%; */
  padding: 0px 29px;
  width: 100%;
  box-sizing: border-box;
}

.ig-desktop-video-left-arrow {
  position: absolute;
  left: -70px;
  /* top: 300px; */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.ig-desktop-video-right-arrow {
  position: absolute;
  right: -70px;
  /* top: 300px; */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.ig-desktop-video-reload-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.ig-desktop-video-reload-button img {
  width: 48px;
  height: 48px;
}

.ig-desktop-video-reload-button p {
  margin-top: 4px;
  color: #FFF;
  text-shadow: 0px 2.5222222805023193px 2.5222222805023193px rgba(0, 0, 0, 0.05);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.222px;
  letter-spacing: 0.126px;
}

.video-carousal-desktop-button button {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  border-radius: 10px;
  background: #021a94;
  padding: 10px;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.331px;
  letter-spacing: 0.187px;
  width: 100%;
  margin-top: 27px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ig-desktop-video-carousal-heading {
  /* margin-left: 29px; */
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 2.5222222805023193px 2.5222222805023193px rgba(0, 0, 0, 0.05);
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.222px;
  letter-spacing: 0.126px;
}

.ig-desktop-video-carousal-sub-heading {
  /* margin-left: 29px; */
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 2.5222222805023193px 2.5222222805023193px rgba(0, 0, 0, 0.05);
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.222px;
  /* 142.857% */
  letter-spacing: 0.126px;
}

.ig-heading1-video-carousal {
  position: absolute;
  bottom: 165px;
  margin-left: 29px;
  color: #fff;
  text-shadow: 0px 2.5222222805023193px 2.5222222805023193px rgba(0, 0, 0, 0.05);
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.222px;
  /* 142.857% */
  letter-spacing: 0.126px;
}

.modalpopup-no-bg :global .vms_modalpop_modalcontainer {
  left: 50%;
  top: 4%;
  transform: translate(-50%);
  bottom: inherit;
  right: inherit;
  min-width: 25vw;
  max-width: 25vw;
  background-color: unset;
  /* right: 20%; */
  /* bottom: 50%; */
  /* width: 25vw; */
  /* height: 150px; */
  /* height: 92vh; */
  /* border-radius: 12px; */
    /* overflow: hidden; */
}

.offers-for-you-heading-main {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  /* elivation - 2 */
  text-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.1px;
}

.offers-for-you-heading {
  text-transform: uppercase;
  color: var(--black-90, rgba(0, 0, 0, 0.9));
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 130% */
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

.ig-app-and-signup {
  padding-top: 35px;
  padding-bottom: 65px;
}

.from-our-blogs-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-top: 12px;
  color: #222222;
}

.Explore-sliderboxinner {
  border-radius: 12px;
  background-size: cover;
}

.from-our-blogs-container li {
  /* width: 278px; */
  /* height: 360px; */
  /* margin-right: 15px;
  padding: 0px; */
  border-radius: 12px;
}

.explore-for-you-indigo {
  background: rgba(0, 0, 0, 0.02);
}

.offers-for-you-container .ig-slider-main-container .offersslider li {
  width: 277px;
  height: 205px;
  margin-right: 17px;
  padding: 0px;
}

.ig-slider-main-container .hiiqmU {
  position: unset;
}

.explore-for-you-indigo {
  padding-top: 72px;
  padding-bottom: 72px;
}

.seo-container {
  background: #f4f4f4;
  /* padding-top: 31px; */
  padding-bottom: 60px;
}

.seo-data-container h6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #4d4d4f;
  margin: 0px;
  padding-bottom: 14px;
  padding-top: 30px;
}

.seo-data-container span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #666666;
  cursor: pointer;
}

.from-our-blogs-container {
  margin-bottom: 79px;
  position: relative;
  margin-top: 65px;
}

.why-choose-us-heading {
  color: var(--80, rgba(0, 0, 0, 0.8));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: 0.1px;
  padding: 0 0 2px 0;
}

.why-choose-us-sub_heading {
  color: var(--black-50, rgba(0, 0, 0, 0.5));
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.1px;
}

.offers-for-you-container>div>div>div>div:nth-child(1),
.explore-with-ig-container>div>div:nth-child(1),
.from-our-blogs-container>div>div:nth-child(1) {
  height: auto;
  /* padding-top: 60px; */
}

.offers-for-you-container>h2,
.explore-with-ig-container>h2,
.from-our-blogs-container>h2 {
  /* position: absolute !important; */
}

.ig-slider-main-container-rel :hover {
  border-radius: 12px;
  position: relative;
  background-size: cover;
}

.explore-video-inner-container {
  position: relative;
}

.explore-video-container .bottom-text-slider {
  position: absolute;
  bottom: 32px;
  left: 32px;
}

.explore-video-container .offers-for-you-sub_headings {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  padding-bottom: 4px;
}

.explore-with-ig-container :global .vms_carousel_childitems {
  background: rgba(0, 0, 0, 0.02);
}

@media (min-width: 1024px) and (max-width: 1279px) {

  .why-choose-us-sub-img_container{
    height: unset;
  }

  .why-choose-us-sub-img_container img{
    height: unset;
  }

  .recent-search-inner-container {
    /* padding: 0 30px; */
    width: 986px;
    margin: 46px auto 48px auto;
  }

  .why-choose-us-main {
    /* padding: 0 30px; */
    width: 986px;
    margin: 46px auto 48px auto;
  }

  .why-choose-us-main>div {
    gap: 10px;
  }

  .offers-for-you-container {
    /* padding: 0 16px; */
    width: 986px;
    margin: 46px auto 48px auto;
  }

  .explore-with-ig-container {
    /* padding: 0 30px; */
    width: 986px;
    margin: 46px auto 48px auto;
    position: relative;
  }

  .from-our-blogs-container {
    /* padding: 0 16px; */
    width: 986px;
    margin: 46px auto 48px auto;
  }

  .seo-data-container {
    /* padding: 0 30px; */
    width: 986px;
    margin: 46px auto 48px auto;
  }

  .ig-app-and-signup {
    /* padding-left: 30px; */
    width: 986px;
    margin: 46px auto 48px auto;
  }

  .ig-app-and-signup-box {
    /* padding-left: 30px; */
    /* padding: 30px; */
    width: 986px;
    margin: 46px auto 48px auto;
  }

  .why-choose-us-heading {
    color: var(--80, rgba(0, 0, 0, 0.8));
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: 0.1px;
    padding: 0 0 2px 0;
  }

  .why-choose-us-sub_heading {
    color: var(--black-50, rgba(0, 0, 0, 0.5));
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
  }
  /* .offers-for-you-container li{
    width: 250px;
  } */
}

.Recent-search-mobile-container :global .react-multi-carousel-list{ 
  padding-top: 46px;
}

.Recent-search-mobile-container .Recent-search-heading {
  margin-bottom: 24px;
  color: #222;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.24px;
  position: absolute;
}

.Recent-search-container{
  position: relative;
}

.Recent-search-container .Recent-search-heading{
  /* margin-bottom: 32px; */
  color: #000;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 93.75% */
  letter-spacing: 0.3px;
  /* position: absolute; */
}


.Recent-search-container :global .vms_carousel_container {
  margin-top: 32px;
  /* padding-bottom: 20px; */
  padding-bottom: 10px;
}

.Recent-search-container :global .vms_carousel_container .react-multi-carousel-list{
  border-radius: 8px;
  padding: 10px 0;
}

.Recent-search-container :global .vms_carousel_container .react-multi-carousel-list .react-multi-carousel-item{
  /* margin-right: 16px; */
  /* padding: 0 8px; */
}

.Recent-search-container :global .vms_carousel_container .react-multi-carousel-list .react-multi-carousel-item > div{
  /* margin-right: 16px; */
  padding: 0 8px;
}

.offers-for-you-slider :global .vms_carousel_container .react-multi-carousel-list{
  /* padding-top: 72px; */
  border-radius: 12px;
}

.offers-for-you-slider :global .vms_carousel_container .react-multi-carousel-list .react-multi-carousel-item{
  /* margin-right: 17px; */

}

.explore-with-ig-container :global .vms_carousel_container {
  margin-top: 48px;
}

.explore-with-ig-container :global .vms_carousel_container .react-multi-carousel-list{
  border-radius: 12px;
}

.explore-with-ig-container :global .vms_carousel_container .react-multi-carousel-list .react-multi-carousel-item{
  /* margin-right: 15px; */
}

.from-our-blogs-container :global .vms_carousel_container {
  margin-top: 33px;
}

.from-our-blogs-container :global .vms_carousel_container .react-multi-carousel-list{
  border-radius: 8px;

}


.from-our-blogs-container :global .vms_carousel_container .react-multi-carousel-list .react-multi-carousel-item{
  /* margin-right: 16px; */
}

.from-our-blogs-container  .sliderboxblog{
  /* margin: 0 8px;  */
  margin-right: 16px;
  cursor: pointer;
}

/* .Recent-search-container .Recent-search-data-main-container {
  display: flex;
} */

.Recent-search-data-container {
  /* margin: 0 8px; */
  /* margin-right: 16px; */
  padding: 16px 25px 16px 25px;
  border-radius: 8.917px;
  border: 1px solid #F4F4F4;
  background: #FFF;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  cursor: pointer;
} 


.Recent-search-location {
  display: flex;
  margin-bottom: 6px;
  gap: 8px;
  align-items: flex-end;
}

.Recent-search-mobile-container .Recent-search-location{
  margin-bottom: 8px;
}

.Recent-search-location-icon-container{
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  /* display: grid;
  place-items: center; */
}

.Recent-search-location img{
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

.Recent-search-mobile-container .Recent-search-dest-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.90);
  font-size: 14px;
  letter-spacing: 0.1px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.Recent-search-container .Recent-search-dest-name{
  color: #000;
  font-family: 'roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.343px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Recent-search-mobile-container .Recent-search-guest-pax,
.Recent-search-mobile-container .Recent-search-date-pax {
  color: var(--black-50, rgba(0, 0, 0, 0.50));
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.1px;
  margin-bottom: 2px;
  display: block;
}

.Recent-search-container .Recent-search-date-pax{
  padding-left: 30px;
}
.Recent-search-container .Recent-search-guest-pax,
.Recent-search-container .Recent-search-date-pax{
  color: #939598;
  font-family: 'roboto';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.343px;
}

.Recent-search-guest-pax{
  margin-bottom: 0;

}

.recent-search-inner-container {
  margin-bottom: 37px;
  margin-top: 48px;
  position: relative;
}

.recent-search-inner-container :global .vms_carousel_childcontainer{
  /* width: 100%; */
}

.recent-search-inner-container :global .vms_carousel_childitems{
  /* gap: 16px; */
  padding: 14px 0;
  /* width: 100%; */
}

.recent-search-inner-container :global .vms_carousel_childitem {
  /* padding: 0px; */
  /* width: 276px; */
  height: 81px;
  flex-shrink: 0;
  /* border-radius: 8.917px;
  border: 1px solid #F4F4F4;
  background: #FFF;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  box-sizing: border-box; */
}


.offers-for-you-slider ol{
  margin-left: -8px;
}
.explore-with-ig-container ol{
  margin-left: -8px;
}
.from-our-blogs-container ol{
  margin-left: -8px;
}

.ig-mobile-popup-sub-description p{
  padding-bottom: 27.41px;
  color: #FFF;
  font-family: Roboto;
  font-size: 17px;
}

@media screen and (min-width:1000px) and (max-width:1024px) {
.recent-search-inner-container :global .vms_carousel_childitem {
  height: auto;
}
.recent-search-inner-container :global .vms_carousel_childitems{
  gap: 16px;
  padding: 14px 0;
  /* width: 100%; */
}
.Recent-search-data-container {
  padding: 10px 10px 10px 10px;
} 
}

.Recent-search-container :global .vms_carousel_childitems{
  margin-left: -3px;
}



@media (min-width: 1024px) and (max-width: 1366px){
.ig-HomePage-Banner-container{
  padding-top: 90px;
}
}

/* .popular_amongst_traveller_container{
  //background-color: red;
  height: 100%;
} */

.popular_amongst_traveller_container_img{
  max-width: 273px;
  position: relative;
  height: 100%;
  border-radius: 12px;
  margin-right: 17px;
  overflow: hidden;
  transition: .6s ease-in-out;
  cursor: pointer;
}
.popular_amongst_traveller_container_img:hover.popular_amongst_traveller_container_img img{
  transform: scale(102%);
  transition: .6s ease-in-out;
}
.popular_amongst_traveller_container_img img{
  border-radius: 12px;
  height: 100% !important;
}

.popular_amongst_traveller_container_img_text{
  position: absolute;
  bottom: 0;
  left: 3px;
  background: linear-gradient(transparent, rgba(0, 0, 0, .3), rgba(0, 0, 0, .8));
  width: 100%;
  color: white;
  padding: 20px;
  margin: auto;
  border-radius: 12px;
  font-family: 'Roboto';
}

.popular_amongst_traveller_container_img_text p{
  font-size: 16px;
  padding-bottom: 5px;
}

.Top_monsoon_container_img_text p{
  font-family: 'Roboto';
font-size: 20px;
font-weight: 700;
line-height: 26px;
letter-spacing: 0.10000000149011612px;
text-align: center;
color: #25304B;
text-decoration: none;
text-transform: capitalize;
}
.Top_monsoon_slider li a{
  text-decoration: none;
}
.Top_monsoon_slider li{
 height: max-content;
}

.Top_monsoon_slider .popular_amongst_traveller_container_img img{
  height: 205px !important;
}

.popular_amongst_traveller_container_img_text span{
  font-size: 14px;
  text-transform: capitalize;
}
.powered_by_text_contain_parent{
  justify-content: left !important;
  margin-left: 10px;
}

.ig_seo_link_tag{
  text-decoration: none;
  color: #666666;
}

@media screen and (max-width: 767px) {
 
  .seo-data-container{
    padding: 16px;
  }
}

